import places from 'places.js';

const placesAutocompletePickup = () => {
  const placesAutocomplete = places({    
    container: document.querySelector('#search_address')
    }
  ).configure({
    type: 'address'
  });
  placesAutocomplete.on('change', function resultSelected(e) {  
    document.querySelector('#pickup_city').value = e.suggestion.city || '';
    document.querySelector('#pickup_zip_code').value = e.suggestion.postcode || '';
    document.querySelector('#pickup_province').value = e.suggestion.administrative || '';
    document.querySelector('#pickup_address').value = e.suggestion.name || '';    
    console.log(e.suggestion);    
  });
};

export { placesAutocompletePickup };

