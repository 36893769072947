import places from 'places.js';

const placesAutocompleteUser = () => {
  const placesAutocomplete = places({    
    container: document.querySelector('#search_address')
    }
  ).configure({
    type: 'address'
  });
  placesAutocomplete.on('change', function resultSelected(e) {  
    document.querySelector('#user_city').value = e.suggestion.city || '';
    document.querySelector('#user_zipcode').value = e.suggestion.postcode || '';
    document.querySelector('#user_province').value = e.suggestion.administrative || '';
    document.querySelector('#user_address').value = e.suggestion.name || '';    
    console.log(e.suggestion);    
  });
};

export { placesAutocompleteUser };

