import places from 'places.js';

const placesAutocomplete = () => {
  const placesAutocomplete = places({    
    container: document.querySelector('#search_address')
    }
  ).configure({
    type: 'address'
  });
  placesAutocomplete.on('change', function resultSelected(e) {   
    document.querySelector('#shipping_city').value = e.suggestion.city || '';
    document.querySelector('#shipping_zipcode').value = e.suggestion.postcode || '';
    document.querySelector('#shipping_province').value = e.suggestion.administrative || '';
    document.querySelector('#shipping_address').value = e.suggestion.name || '';
    document.querySelector('#shipping_latitude').value = e.suggestion.latlng.lat || '';
    document.querySelector('#shipping_longitude').value = e.suggestion.latlng.lng || '';
    console.log(e.suggestion);    
  });
};

export { placesAutocomplete };

